/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { graphql, Link } from 'gatsby'
import * as sH from 'sanitize-html'

const Container = styled.section`
  ${tw`bg-ricoDarkGray`}
`
const Wrapper = styled.section`
  ${tw`max-w-7xl mx-auto py-16 flex flex-col md:flex-row md:flex-wrap items-center text-white px-8 justify-between`}
`
const Bullets = styled.div`
  ${tw`w-full lg:grid lg:grid-cols-2 lg:gap-x-16`}
`
const SideLink = styled(props => <Link {...props} />)`
  ${tw`order-last md:order-none font-bold text-white border-b border-white self-start pl-6 mb-1 no-underline inline-block mt-5`}
`
const BulletTitle = styled.h3`
  ${tw`text-2xl md:text-4xl mb-3`}
`
const BulletCopy = styled.p`
  ${tw`text-white text-xl font-light max-w-[540px]`}
`
const Headline = styled.h2`
  ${tw`w-full lg:w-2/3 text-4xl md:text-6xl mb-6 lg:mb-16`}
`
const Bullet = styled.div`
  ${tw`pb-6 lg:pb-0`}
`
const Dot = '<span class="text-primary">.</span>'

const ServicePromo = ({ data }) => {
  const { headline: title, bullets, link } = data
  return (
    <Container>
      <Wrapper>
        {title && <Headline dangerouslySetInnerHTML={{ __html: sH(title) }} />}
        {link && <SideLink to={link.url} dangerouslySetInnerHTML={{ __html: sH(link.title) }} />}
        <Bullets>
          {Object.values(bullets).map(({ headline, copy } = {}) => (
            <Bullet key={headline}>
              <BulletTitle dangerouslySetInnerHTML={{ __html: `${sH(headline)}${Dot}` }} />
              <BulletCopy dangerouslySetInnerHTML={{ __html: sH(copy) }} />
            </Bullet>
          ))}
        </Bullets>
      </Wrapper>
    </Container>
  )
}

export default ServicePromo

ServicePromo.propTypes = {
  data: PropTypes.object.isRequired,
  headline: PropTypes.string,
}

export const servicepromoData = graphql`
  fragment ServicePromo on WpPage_Pagebuilder_Layouts_ServicePromo {
    fieldGroupName
    headline
    bullets {
      second {
        copy
        headline
      }
      first {
        headline
        copy
      }
    }
    link {
      target
      title
      url
    }
  }
`
